// This file was autogenerated. Please do not change.
export interface IUserBankRequisitesRequestData {
    accountId: string;
}

export default class UserBankRequisitesRequestData {
    readonly _accountId: string;

    get accountId(): string {
        return this._accountId;
    }

    constructor(props: IUserBankRequisitesRequestData) {
        this._accountId = props.accountId.trim();
    }

    serialize(): IUserBankRequisitesRequestData {
        const data: IUserBankRequisitesRequestData = {
            accountId: this._accountId,
        };
        return data;
    }

    toJSON(): IUserBankRequisitesRequestData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
